exports.addr = [
    {
        "_id": {
            "province_name": "บุรีรัมย์"
        }
    },
    {
        "_id": {
            "province_name": "เพชรบูรณ์"
        }
    },
    {
        "_id": {
            "province_name": "สระบุรี"
        }
    },
    {
        "_id": {
            "province_name": "ชลบุรี"
        }
    },
    {
        "_id": {
            "province_name": "ภูเก็ต"
        }
    },
    {
        "_id": {
            "province_name": "สงขลา"
        }
    },
    {
        "_id": {
            "province_name": "ชุมพร"
        }
    },
    {
        "_id": {
            "province_name": "ปทุมธานี"
        }
    },
    {
        "_id": {
            "province_name": "สมุทรปราการ"
        }
    },
    {
        "_id": {
            "province_name": "น่าน"
        }
    },
    {
        "_id": {
            "province_name": "ระนอง"
        }
    },
    {
        "_id": {
            "province_name": "สมุทรสาคร"
        }
    },
    {
        "_id": {
            "province_name": "สตูล"
        }
    },
    {
        "_id": {
            "province_name": "ตราด"
        }
    },
    {
        "_id": {
            "province_name": "บึงกาฬ"
        }
    },
    {
        "_id": {
            "province_name": "เพชรบุรี"
        }
    },
    {
        "_id": {
            "province_name": "มหาสารคาม"
        }
    },
    {
        "_id": {
            "province_name": "ลพบุรี"
        }
    },
    {
        "_id": {
            "province_name": "สิงห์บุรี"
        }
    },
    {
        "_id": {
            "province_name": "พระนครศรีอยุธยา"
        }
    },
    {
        "_id": {
            "province_name": "ลำพูน"
        }
    },
    {
        "_id": {
            "province_name": "ฉะเชิงเทรา"
        }
    },
    {
        "_id": {
            "province_name": "ร้อยเอ็ด"
        }
    },
    {
        "_id": {
            "province_name": "อุทัยธานี"
        }
    },
    {
        "_id": {
            "province_name": "ชัยนาท"
        }
    },
    {
        "_id": {
            "province_name": "ลำปาง"
        }
    },
    {
        "_id": {
            "province_name": "นครปฐม"
        }
    },
    {
        "_id": {
            "province_name": "นนทบุรี"
        }
    },
    {
        "_id": {
            "province_name": "สมุทรสงคราม"
        }
    },
    {
        "_id": {
            "province_name": "พะเยา"
        }
    },
    {
        "_id": {
            "province_name": "กรุงเทพมหานคร"
        }
    },
    {
        "_id": {
            "province_name": "อุบลราชธานี"
        }
    },
    {
        "_id": {
            "province_name": "พิษณุโลก"
        }
    },
    {
        "_id": {
            "province_name": "สุรินทร์"
        }
    },
    {
        "_id": {
            "province_name": "แพร่"
        }
    },
    {
        "_id": {
            "province_name": "กระบี่"
        }
    },
    {
        "_id": {
            "province_name": "แม่ฮ่องสอน"
        }
    },
    {
        "_id": {
            "province_name": "พังงา"
        }
    },
    {
        "_id": {
            "province_name": "สุราษฎร์ธานี"
        }
    },
    {
        "_id": {
            "province_name": "ปัตตานี"
        }
    },
    {
        "_id": {
            "province_name": "ปราจีนบุรี"
        }
    },
    {
        "_id": {
            "province_name": "เลย"
        }
    },
    {
        "_id": {
            "province_name": "นครนายก"
        }
    },
    {
        "_id": {
            "province_name": "กำแพงเพชร"
        }
    },
    {
        "_id": {
            "province_name": "ยะลา"
        }
    },
    {
        "_id": {
            "province_name": "นครศรีธรรมราช"
        }
    },
    {
        "_id": {
            "province_name": "จันทบุรี"
        }
    },
    {
        "_id": {
            "province_name": "หนองคาย"
        }
    },
    {
        "_id": {
            "province_name": "ราชบุรี"
        }
    },
    {
        "_id": {
            "province_name": "อำนาจเจริญ"
        }
    },
    {
        "_id": {
            "province_name": "เชียงราย"
        }
    },
    {
        "_id": {
            "province_name": "ขอนแก่น"
        }
    },
    {
        "_id": {
            "province_name": "สุโขทัย"
        }
    },
    {
        "_id": {
            "province_name": "ชัยภูมิ"
        }
    },
    {
        "_id": {
            "province_name": "นครราชสีมา"
        }
    },
    {
        "_id": {
            "province_name": "อุดรธานี"
        }
    },
    {
        "_id": {
            "province_name": "มุกดาหาร"
        }
    },
    {
        "_id": {
            "province_name": "เพชรบุรี "
        }
    },
    {
        "_id": {
            "province_name": "สงขลา "
        }
    },
    {
        "_id": {
            "province_name": "กาฬสินธุ์"
        }
    },
    {
        "_id": {
            "province_name": "กาญจนบุรี"
        }
    },
    {
        "_id": {
            "province_name": "ระยอง"
        }
    },
    {
        "_id": {
            "province_name": "ตาก"
        }
    },
    {
        "_id": {
            "province_name": "เชียงใหม่"
        }
    },
    {
        "_id": {
            "province_name": "ศรีสะเกษ"
        }
    },
    {
        "_id": {
            "province_name": "สระแก้ว"
        }
    },
    {
        "_id": {
            "province_name": "พัทลุง"
        }
    },
    {
        "_id": {
            "province_name": "ประจวบคีรีขันธ์"
        }
    },
    {
        "_id": {
            "province_name": "สุพรรณบุรี"
        }
    },
    {
        "_id": {
            "province_name": "สกลนคร"
        }
    },
    {
        "_id": {
            "province_name": "นราธิวาส"
        }
    },
    {
        "_id": {
            "province_name": "ยโสธร"
        }
    },
    {
        "_id": {
            "province_name": "ตรัง"
        }
    },
    {
        "_id": {
            "province_name": "หนองบัวลำภู"
        }
    },
    {
        "_id": {
            "province_name": "อุตรดิตถ์"
        }
    },
    {
        "_id": {
            "province_name": "นครสวรรค์"
        }
    },
    {
        "_id": {
            "province_name": "พิจิตร"
        }
    },
    {
        "_id": {
            "province_name": "อ่างทอง"
        }
    },
    {
        "_id": {
            "province_name": "นครพนม"
        }
    }
]